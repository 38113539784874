import apiManager from '../../api';


const analyticStore = {
  namespaced: true,
  state: {
    session: { key: 'sessionId', value: null },
    lastNavTime: null,
  },
  mutations: {
    setSession: (state, session) => {
      state.session = session;
    },
    setLastNavTime: (state, lastNavTime) => { state.lastNavTime = lastNavTime; },
  },
  actions: {
    async add({ dispatch }, analytic) {
      const resp = await dispatch('request', { req: apiManager.analytic.add(analytic) }, { root: true });
      return resp;
    },
    async startSession({ dispatch, commit }, userId) {
      const resp = await dispatch('request', { req: apiManager.analytic.startSession(userId) }, { root: true });
      commit('setSession', resp.data);
    },
    async clearSession({ commit }) {
      commit('setSession', { key: 'sessionId', value: null });
    },
    async updateSession({ dispatch, state }) {
      await dispatch('request', { req: apiManager.analytic.updateSession(state.session.value) }, { root: true });
    },
    async updateLastNavTime({ commit }) {
      commit('setLastNavTime', new Date());
    },
  },
  getters: {
    sessionId: state => state.session.value,
    lastNav: state => state.lastNavTime,
  },
};


export default analyticStore;
