<template>
  <nav v-show="showNavigationBar" :class="{
      navbar: !onHomePage,
      navbarHome: onHomePage,
      active: isActive
    }">
    <div @click="toggleMenu()" :class="{ active: isActive }" class="navbar-toggle">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div :class="{ active: isActive }" class="routerLinks">
      <!-- @click.native="
                    clearTenetsAndAreas();
                    closeMenu();
                    $analytics.trackEventBtnNavBar('Home')" -->
      <router-link
        v-show="!onHomePageCheck"
        class="link"

        to="/"
      >
        <div class="txt">{{this.navBar.home}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Stories')" -->
      <router-link
        class="link"

        to="/stories"
      >
        <div class="txt">{{this.navBar.stories}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Community')" -->
      <router-link
        class="link"

        to="/mycommunity"
      >
        <div class="txt">{{this.navBar.community}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Profile')" -->
      <router-link
        class="link"

        :to="{ path: 'profile', query: { id: profile.id }}"
      >
        <div class="txt">{{this.navBar.profile}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Resources')" -->
      <router-link
        class="link"

        to="/resources"
      >
        <div class="txt">{{this.navBar.resources}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Admin')" -->
      <router-link
        v-if="isAdmin"
        class="link"

        to="/admin"
      >
        <div class="txt">{{this.navBar.admin}}</div>
      </router-link>
      <!-- @click.native="closeMenu(); $analytics.trackEventBtnNavBar('Logout')" -->
      <router-link
        class="link"

        to="/logout"
      >
        <div class="txt">{{this.navBar.logout}}</div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import dataNavBar from '../data/navBarCopy';
import helperMethods from '../mixins/helperMethods.vue';

export default {
  name: 'NavigationBar',
  mixins: [helperMethods],
  data() {
    return {
      navBar: dataNavBar.default,
      isActive: false,
      navBarZIndex: 'navbar',
    };
  },
  computed: {
    onHomePage() {
      if (this.$route.name === 'home') {
        this.assignNavCopy(dataNavBar.home);
        return true;
      }
      this.assignNavCopy(dataNavBar.default);
      return false;
    },
    onHomePageCheck() {
      if (this.$route.name === 'home') {
        return true;
      }
      return false;
    },
    showNavigationBar() {
      if (this.$route.name === 'login') {
        return false;
      }

      return !this.isOnLogoutPage();
    },
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
    profile() {
      return this.$store.getters['userStore/profile'];
    },
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    closeMenu() {
      this.isActive = false;
    },
    assignNavCopy(navCopyObject) {
      this.navBar = navCopyObject;
    },
    clearTenetsAndAreas() {
      this.$store.dispatch('tenetStore/reset');
      this.$store.dispatch('businessAreaStore/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbarHome {
  position: absolute;
  top: 95px;
  left: 15px;
  right: 0;
  @include font-size(1rem);
  z-index: 1;

  &.active {
    z-index: 1000;
  }

  @include respond(md) {
    width: 160px;
  }

  .navbar-toggle {
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;

    @include respond(md) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      right: 0;
      transition: 0.3s ease-in-out;
      background: rgba(255, 255, 255, 0.6);
      background-size: cover;
      background-position: -6px;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 8px;
        left: 0;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          top: 10px;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 10px;
          transform: rotate(-135deg);
        }
      }
    }
  }

  .routerLinks {
    display: flex;
    flex-direction: column;
    color: white;
    transform: translateX(-1000px);
    transition: all 0.2s ease;

    &.active {
      transform: translateX(0);
      background: rgba($color: #000000, $alpha: 0.7);
      width: 100%;
      margin-left: -15px;
      padding-left: 15px;
      transition: all 0.2s ease;

      @include respond(md) {
        margin-left: 0;
        background: transparent;
      }
    }

    .link {
      padding-left: 0;

      @include respond(md) {
        white-space: pre;
        padding: 8px 0;
        margin: 0;
      }

      .txt {
        display: inline-block;
        position: relative;
        padding-bottom: 10px;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          height: 3px;
          background: $LincolnOrange;
          transition: all 0.5s ease;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }

    @include respond(md) {
      transform: translateX(0);
      display: flex;
      margin-top: 25px;
    }
  }
}

.navbar {
  padding: 15px 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  @include font-size(0.875rem);
  background: #fff;

  .navbar-toggle {
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;

    @include respond(md) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      right: 0;
      transition: 0.3s ease-in-out;
      background: rgba(0, 0, 0, 0.6);
      background-size: cover;
      background-position: -6px;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 8px;
        left: 0;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          top: 10px;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 10px;
          transform: rotate(-135deg);
        }
      }
    }
  }

  .routerLinks {
    display: flex;
    position: absolute;
    flex-direction: column;
    color: black;
    transform: translateX(-1000px);
    transition: all 0.2s ease;

    &.active {
      transform: translateX(0);
      background: #fff;
      width: 100%;
      margin-left: -15px;
      transition: all 0.2s ease;

      @include respond(md) {
        margin-left: 0;
        background: transparent;
      }
    }

    @include respond(md) {
      position: relative;
      flex-direction: row;
      justify-content: center;
      transform: translateX(0);
      transition: none;
    }
  }
}

.link {
  padding: 15px 15px;
  color: inherit;
  text-decoration: inherit;
  font-weight: normal;

  @include respond(md) {
    margin: 0 10px;
    padding: 15px 5px;
  }

  .txt {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;

    &:hover {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: $LincolnOrange;
      }
    }
  }
}

.router-link-exact-active {
  font-weight: bold;
}
.search {
  background-color: $LincolnGrayLight;
  border: rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.brandStorySubmission {
  position: absolute;
  left: 30%;
  padding-top: 180px;
}
</style>
