import apiManager from '../../api';

const whpcStoryStatusStore = {
  namespaced: true,
  state: {
    statuses: [],
  },
  mutations: {
    setWhpcStoryStatuses: (state, statuses) => {
      state.statuses = statuses;
    },
  },
  actions: {
    async getWhpcStoryStatuses({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.whpcStoryStatus.getAll(true) }, { root: true });
      return commit('setWhpcStoryStatuses', resp.data);
    },
  },
  getters: {
    statuses: state => state.statuses,
  },
};

export default whpcStoryStatusStore;
