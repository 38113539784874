<template lang="html">

<div v-show="showSearch" id="search-bar" class="search-bar">
  <input class="search-bar-input"
  placeholder="Search" v-on:keyup.enter="search" v-model="searchString">
  <div class="icon" @click="search">
    <font-awesome-icon :icon="['fal', 'search']" size="2x" />
  </div>
</div>

</template>

<script lang="js">

export default {
  name: 'search-bar',
  props: [],
  data() {
    return {
      searchString: '',
    };
  },
  methods: {
    async search() {
      await this.$store.dispatch('searchStore/search',
        {
          searchString: this.searchString,
          isGuildVisible: this.isGuildVisible,
        });
      this.$router.push('search');
      this.searchString = '';
    },
  },
  computed: {
    showSearch() {
      return this.$route.name !== 'login';
    },
    isGuildVisible() {
      return this.$store.getters['userStore/isGuildVisible'];
    },
  },
};
</script>

<style scoped lang="scss">
#search-bar {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #fff;
  border: 1px solid #eee;
  position: relative;

  @include respond(md) {
    width: 340px;
    position: absolute;
    right: 265px;
    height: auto;
    border-radius: 8px;
  }

  .search-bar-input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 12px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    flex: 1;
    background: #eee;

    @include respond(md) {
      border-radius: 8px;
      padding-left: 12px;
      height: auto;
    }

    &:focus {
      background-color: #fff;
      outline: none;
    }
  }

  .icon {
    position: absolute;
    top: 25px;
    right: 12px;
    transition: all 0.2s ease;

    &:hover {
      color: $LincolnOrange;
      cursor: pointer;
    }

    @include respond(md) {
      top: 5px;
    }
  }
}
</style>
