import baseCRUDAPI from './baseCRUDAPI';

export default class Asset extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/asset';
  }

  file(file) {
    const path = `${this.apiBaseName}/file`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return this.axiosObject.post(path, file, config);
  }

  multifiles(files) {
    const path = `${this.apiBaseName}/multifiles`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return this.axiosObject.post(path, files, config);
  }

  download(id) {
    const config = { responseType: 'blob' };
    return this.axiosObject.get(`${this.apiBaseName}/${id}/download`, config);
  }

  filelist(assetIds) {
    return this.axiosObject.post(`${this.apiBaseName}/filelist`, assetIds);
  }

  base64(id) {
    return this.axiosObject.get(`${this.apiBaseName}/${id}/base64`);
  }

  filepath(id) {
    const relativePath = this.axiosObject.get(`${this.apiBaseName}/${id}/filepath`);
    return `${process.env.VUE_APP_BASE_API_URL}/${relativePath}`;
  }
}
