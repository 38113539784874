import apiManager from '../../api';

const searchStore = {
  namespaced: true,
  state: {
    searchResults: [],
  },
  mutations: {
    setSearchResults: (state, searchResults) => { state.searchResults = searchResults; },
  },
  actions: {
    async search({ commit, dispatch }, searchString, isGuildVisible) {
      const resp = await dispatch('request',
        { req: apiManager.search.search(searchString, isGuildVisible) },
        { root: true });
      return commit('setSearchResults', resp.data);
    },
  },
  getters: {
    searchResults: state => state.searchResults,
  },
};

export default searchStore;
