import apiManager from '../../api';

const whpcStore = {
  namespaced: true,
  state: {
    stories: [],
    currentStory: null,
    publishedStories: [],
  },
  mutations: {
    setStories: (state, stories) => { state.stories = stories; },
    setCurrentStory: (state, currentStory) => {
      state.currentStory = currentStory;
    },
    setPublishedStories: (state, publishedStories) => {
      state.publishedStories = publishedStories;
    },
  },
  actions: {
    async getStories({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.whpc.getAll(true) }, { root: true });
      return commit('setStories', resp.data);
    },
    async getPublishedStories({ commit, dispatch }) {
      const resp = await dispatch('request', {
        req: apiManager.whpc.published(true),
      }, { root: true });
      return commit('setPublishedStories', resp.data);
    },
    async getStory({ commit, dispatch }, id) {
      const { data } = await dispatch('request', {
        req: apiManager.whpc.get(id, true),
      }, { root: true });

      return commit('setCurrentStory', data);
    },
    async setCurrentStory({ commit }, story) {
      return commit('setCurrentStory', story);
    },
    async submitStory({ commit, dispatch }, object) {
      await dispatch('request', { req: apiManager.whpc.post(object) }, { root: true });
      const resp = await dispatch('request', { req: apiManager.whpc.published(true) }, { root: true });
      return commit('setPublishedStories', resp.data);
    },
  },
  getters: {
    stories: state => state.stories,
    currentStory: state => state.currentStory,
    publishedStories: state => state.publishedStories,
  },
};

export default whpcStore;
