<template>
  <div :class="onHomePage" v-show="!isOnLogoutPage()">

    <div class="wrapper">
      <div class="logo">
        <router-link @click.native="clearTenetsAndAreas(); $analytics.trackEventBtnNavBar('Home'); "
                     to="/">
          <LincolnEffortlessExperienceLogo class="imgLogo light" />
          <LincolnEffortlessExperienceLogoDark class="imgLogo dark" />
        </router-link>
      </div>
      <div class="search">
        <div class="search-mobile">
          <SearchMobileContainer />
        </div>
        <div class="search-desktop">
          <SearchBar />
        </div>
      </div>
      <div class="profile">
        <ProfileContainer />
      </div>
    </div>
  </div>
</template>

<script>
import LincolnEffortlessExperienceLogo from './LincolnEffortlessExperienceLogo.vue';
import LincolnEffortlessExperienceLogoDark from './LincolnEffortlessExperienceLogoDark.vue';
import SearchMobileContainer from './searchMobile/SearchMobileContainer.vue';
import SearchBar from './SearchBar.vue';
import ProfileContainer from './profile/ProfileContainer.vue';
import helperMethods from '../mixins/helperMethods.vue';


export default {
  name: 'TopBar',
  mixins: [helperMethods],
  components: {
    LincolnEffortlessExperienceLogo,
    LincolnEffortlessExperienceLogoDark,
    SearchMobileContainer,
    SearchBar,
    ProfileContainer,
  },
  computed: {
    onHomePage() {
      if (this.$route.name === 'home') {
        return 'topBarHome';
      }
      return 'topBar';
    },
    notOnHomePage() {
      if (this.$route.name === 'home') {
        return false;
      }
      return true;
    },
  },
  methods: {

    clearTenetsAndAreas() {
      this.$store.dispatch('tenetStore/reset');
      this.$store.dispatch('businessAreaStore/reset');
    },
  },
};
</script>

<style lang="scss" scoped>
.topBarHome {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: rgba(62, 72, 71, 0.59);

  @include respond(md) {
    background: transparent;
  }

  .wrapper {
    display: flex;
    padding: 0 15px 10px 15px;
    position: relative;
    align-items: center;

    .logo {
      flex-basis: 60%;

      .imgLogo {
        width: 100%;
        max-width: 240px;
        margin-top: 15px;

        &.dark {
          display: none;
        }
      }
    }

    .search {
      z-index: 1;
      align-self: flex-start;
      margin-top: 24px;

      @include respond(md) {
        margin-top: 15px;
      }

      .search-mobile {
        @include respond(md) {
          display: none;
        }
      }

      .search-desktop {
        display: none;

        @include respond(md) {
          display: block;
        }
      }
    }

    .profile {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.topBar {
  background: linear-gradient(
      to right,
      rgba(62, 72, 71, 0.85),
      rgba(62, 72, 71, 0.75)
    ),
    url("../assets/images/homeHeader.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @include respond(md) {
    background: transparent;
    height: 86px;
  }

  .wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 1640px;
    padding: 0 15px 10px 15px;
    position: relative;
    align-items: center;

    .logo {
      flex-basis: 60%;

      .imgLogo {
        width: 100%;
        max-width: 240px;
        margin-top: 15px;

        &.light {
          @include respond(md) {
            display: none;
          }
        }

        &.dark {
          display: none;

          @include respond(md) {
            display: block;
          }
        }
      }
    }

    .search {
      z-index: 1;
      align-self: flex-start;
      margin-top: 24px;

      @include respond(md) {
        margin-top: 15px;
      }

      .search-mobile {
        @include respond(md) {
          display: none;
        }
      }

      .search-desktop {
        display: none;

        @include respond(md) {
          display: block;
        }
      }
    }

    .profile {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
