import baseReadOnlyAPI from './baseReadOnlyAPI';

export default class baseCRUDAPI extends baseReadOnlyAPI {
  post(objectData) {
    return this.axiosObject.post(this.apiBaseName, objectData);
  }

  put(objectData) {
    return this.axiosObject.put(`${this.apiBaseName}`, objectData, {
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  delete(guid) {
    return this.axiosObject.delete(`${this.apiBaseName}/${guid}`);
  }
}
