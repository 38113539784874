import apiManager from '../../api';

const carouselStore = {
  namespaced: true,
  state: {
    carouselTiles: [{}, {}, {}],
  },
  mutations: {
    setCarouselTiles: (state, carouselTiles) => {
      state.carouselTiles = carouselTiles;
    },
  },
  actions: {
    async getCarouselTiles({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.carousel.get(true) }, { root: true });
      return commit('setCarouselTiles', resp.data);
    },
    updateCarouselPosition({ state, commit }, direction) {
      if (direction === 'left') {
        const len = state.carouselTiles.length - 1;
        state.carouselTiles = [state.carouselTiles[len], ...state.carouselTiles.slice(0, len)];
      } else {
        state.carouselTiles = [...state.carouselTiles.slice(1), state.carouselTiles[0]];
      }
      commit('setCarouselTiles', state.carouselTiles);
    },
    async updateCarouselTile({ dispatch }, carouselData) {
      const resp = await dispatch('request', { req: apiManager.carousel.update(carouselData) }, { root: true });
      return resp.data;
    },
  },
  getters: {
    carouselTiles: state => state.carouselTiles,
  },
};

export default carouselStore;
