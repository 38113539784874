<template>
  <div class="imgContainer">
    <div
        class="imgNormal"
        :class="badgeImage"
      >
      </div>
  </div>
</template>

<script>


export default {
  name: 'EngagementLevelBadge',
  props: {
    badge: {},
    colored: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    badgeImage() {
      let className = '';

      if (this.badge) {
        switch (this.badge.name) {
          case 'Lincoln Savvy':
            className = this.colored ? 'SavvyColored' : 'SavvyWhite';
            break;
          case 'Lincoln Influential':
            className = this.colored ? 'InfluentialColored' : 'InfluentialWhite';
            break;
          case 'Lincoln Force':
            className = this.colored ? 'ForceColored' : 'ForceWhite';
            break;
          case 'Lincoln Legend':
            className = this.colored ? 'LegendColored' : 'LegendWhite';
            break;
          default:
            className = this.colored ? 'NotableColored' : 'NotableWhite';
            break;
        }
      }
      return className;
    },
  },
};
</script>

<style lang="scss" scoped>

.imgContainer {
    width: 100%;
    height: 100%;
    position: relative;
    color: black;

  .imgNormal {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &.SavvyColored {
      background-image: url("../../assets/images/badges/colored/LincolnSavvyColored.png");
    }

    &.NotableColored {
      background-image: url("../../assets/images/badges/colored/LincolnNotableColored.png");
    }

    &.LegendColored {
      background-image: url("../../assets/images/badges/colored/LincolnLegendColored.png");
    }

    &.InfluentialColored {
      background-image: url("../../assets/images/badges/colored/LincolnInfluentialColored.png");
    }

    &.ForceColored {
      background-image: url("../../assets/images/badges/colored/LincolnForceColored.png");
    }

   &.SavvyWhite {
      background-image: url("../../assets/images/badges/white/LincolnSavvyWhite.png");
    }

    &.NotableWhite {
      background-image: url("../../assets/images/badges/white/LincolnNotableWhite.png");
    }

    &.LegendWhite {
      background-image: url("../../assets/images/badges/white/LincolnLegendWhite.png");
    }

    &.InfluentialWhite {
      background-image: url("../../assets/images/badges/white/LincolnInfluentialWhite.png");
    }

    &.ForceWhite {
      background-image: url("../../assets/images/badges/white/LincolnForceWhite.png");
    }
  }
}
</style>
