import baseCRUDAPI from './baseCRUDAPI';

export default class ResourceDocument extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/resourceDocument';
  }

  getAll(resourceType, cached) {
    const params = {
      resourceType,
    };

    if (cached) {
      params.unique = Math.round(+new Date() / 1000);
    }

    return this.axiosObject.get(`${this.apiBaseName}/GetAll`, { params });
  }

  getDocument(id, cached) {
    const params = {
      id,
    };

    if (cached) {
      params.unique = Math.round(+new Date() / 1000);
    }

    return this.axiosObject.get(`${this.apiBaseName}/GetDocument`, { params });
  }

  async toggleEnabled(id) {
    const resp = await this.axiosObject.put(`${this.apiBaseName}/ToggleEnabled/${id}`);
    return resp;
  }
}
