import apiManager from '../../api';

const profileViewStore = {
  namespaced: true,
  state: {
    profile: {
      nameFirst: '',
      nameLast: '',
      namePreferred: '',
      points: 0,
      pointsAway: 0,
      engagementLevel: null,
      position: null,
    },
    brandStoriesByUser: [],
  },
  mutations: {
    setProfile: (state, profile) => { state.profile = profile; },
    setBrandStories: (state, brandStories) => { state.brandStoriesByUser = brandStories; },
  },
  actions: {
    async getProfile({ state, commit, dispatch }, id) {
      commit('setBrandStories', []);
      const resp = await dispatch('request', { req: apiManager.user.get(id, true) }, { root: true });
      commit('setProfile', resp.data);
      dispatch('getBrandStoriesByUser');
      return state.profile;
    },
    setProfile({ commit }, userObject) {
      return commit('setProfile', userObject);
    },
    async getBrandStoriesByUser({ commit, dispatch, state }) {
      let resp = { data: [] };
      if (state.profile.id) {
        resp = await dispatch('request', { req: apiManager.brandStory.byUser(state.profile.id, true) }, { root: true });
      }
      return commit('setBrandStories', resp.data);
    },
  },
  getters: {
    profile: state => state.profile,
    brandStoriesByUser: state => state.brandStoriesByUser,
  },
};

export default profileViewStore;
