import baseCRUDAPI from './baseCRUDAPI';

export default class BrandStory extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/brandStory';
  }

  approved(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/approved${cachedParams}`);
  }

  comments(brandStoryId, cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/${brandStoryId}/comments${cachedParams}`);
  }

  favorited(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/favorited${cachedParams}`);
  }

  follows(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/follows${cachedParams}`);
  }

  byUser(userId, cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/byUser/${userId}${cachedParams}`);
  }
}
