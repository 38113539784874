import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line
import router from '../router';
import userStore from './modules/userStore';
import engagementLevelStore from './modules/engagementLevelStore';
import brandStoryStore from './modules/brandStoryStore';
import tenetStore from './modules/tenetStore';
import businessAreaStore from './modules/businessAreaStore';
import brandStoryStatusStore from './modules/brandStoryStatusStore';
import whpcStore from './modules/whpcStore';
import brandStoryLikeStore from './modules/brandStoryLikeStore';
import brandStoryFavoriteStore from './modules/brandStoryFavoriteStore';
import assetStore from './modules/assetStore';
import resourceStore from './modules/resourceStore';
import adminStore from './modules/adminStore';
import userFollowStore from './modules/userFollowStore';
import searchStore from './modules/searchStore';
import profileViewStore from './modules/profileViewStore';
import commentStatusStore from './modules/commentStatusStore';
import whpcStoryStatusStore from './modules/whpcStoryStatusStore';
import userProfileImageStatusStore from './modules/userProfileImageStatusStore';
import carouselStore from './modules/carouselStore';
import buttonSubmitStore from './modules/buttonSubmitStore';
import positionStore from './modules/positionStore';
import analyticTypeStore from './modules/analyticTypeStore';
import analyticStore from './modules/analyticStore';

const hasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

const populateDispatchList = (config, dispatchList = [], lineage = '') => {
  Object.entries(config.modules).forEach(([key, module]) => {
    if (hasProp(module, 'modules')) {
      populateDispatchList(module, dispatchList, `${key}/`);
    }

    if (hasProp(module, 'actions')
      && hasProp(module.actions, 'reset')
      && typeof module.actions.reset === 'function') {
      dispatchList.push(`${lineage}${key}/reset`);
    }
  });

  return dispatchList;
};

const storeConfig = {
  modules: {
    userStore,
    engagementLevelStore,
    brandStoryStore,
    tenetStore,
    businessAreaStore,
    brandStoryStatusStore,
    whpcStore,
    brandStoryLikeStore,
    brandStoryFavoriteStore,
    assetStore,
    resourceStore,
    adminStore,
    userFollowStore,
    searchStore,
    profileViewStore,
    commentStatusStore,
    whpcStoryStatusStore,
    userProfileImageStatusStore,
    carouselStore,
    buttonSubmitStore,
    positionStore,
    analyticTypeStore,
    analyticStore,
  },
  state: {
    dispatchList: [],
  },
  actions: {
    reset({ dispatch }) {
      this.state.dispatchList.forEach(item => dispatch(item));
    },
    request({ dispatch }, { req } = {}) {
      return req
        .then(resp => resp)
        .catch((err) => {
          if (!err.response) {
            dispatch('buttonSubmitStore/badRequest');
          }
          if (err.response.status === 401) {
            dispatch('userStore/logout');
            router.push('login');
          }
          if (err.response.status === 404) {
            return err.response;
          }
          dispatch('buttonSubmitStore/badRequest');
          throw new Error(err.response.status);
        })
        .finally(() => {
        });
    },
  },
};

storeConfig.state.dispatchList = populateDispatchList(storeConfig);

Vue.use(Vuex);

const store = new Vuex.Store(storeConfig);

export default store;
