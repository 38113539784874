/* eslint-disable */
import apiManager from '../api';
import store from '../store';

export class DownloadHelper {
  

  async downloadDocument(resourceDocumentId) {
    store.dispatch('analyticStore/updateLastNavTime');
    const analytic = {
      analyticType: 'DocumentView',
      resourceId: resourceDocumentId,
      userId: store.getters['userStore/userId'],
    };
    store.dispatch('analyticStore/add', analytic);

    const resource = await apiManager.resourceDocument.get(resourceDocumentId);    
    const respData = await store.dispatch('assetStore/getAssetDoNotStore', resource.data.docAssetId);
    const cleanFileName = this.cleanName(respData.fileName, respData.id);

    const fileRes = await this.downloadAsset(respData.id);
    const url = window.URL.createObjectURL(new Blob([fileRes.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', cleanFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadAsset(id) {
    return store.dispatch('assetStore/downloadAsset', id);
  }

  cleanName(assetFileName, id) {
    return assetFileName.toString().replace(`_${id}`, '');
  }
}
