var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profileCompact", on: { click: _vm.clickedBadge } },
    [
      _c(
        "div",
        { staticClass: "imgBadge" },
        [
          _c("EngagementLevelBadge", {
            attrs: { badge: _vm.currentEngagementLevel, colored: true },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "welcomeWrapper" }, [
        _c("div", { staticClass: "txtName" }, [
          _vm._v("Welcome, " + _vm._s(this.profile.nameFirst)),
        ]),
        _c(
          "div",
          {
            staticClass: "circleBorderProfileImg",
            class: _vm.engagementLevelClass,
          },
          [
            _c("UserImageThumbnail", {
              class: "imgProfile",
              attrs: { thumbnailAssetId: _vm.profile.thumbnailAssetId },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }