import apiManager from '../../api';

const userFollowStore = {
  namespaced: true,
  state: {
    followList: [],
    usersToFollowList: [],
    brandStoriesByFollows: [],
  },
  mutations: {
    setFollowList: (state, followList) => { state.followList = followList; },
    setUsersToFollowList: (state, usersToFollowList) => {
      state.usersToFollowList = usersToFollowList;
    },
    removeUserInUsersToFollowList: (state, position) => {
      state.usersToFollowList.splice(position, 1);
    },
    setBrandStoriesByFollow: (state, brandStoriesByFollows) => {
      state.brandStoriesByFollows = brandStoriesByFollows;
    },
  },
  actions: {
    async getFollowList({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.user.getFollowingList(true) }, { root: true });
      return commit('setFollowList', resp.data);
    },
    async getOthersToFollowList({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.user.getOthersToFollow(true) }, { root: true });
      return commit('setUsersToFollowList', resp.data);
    },
    async followUser({ commit, dispatch, state }, userId) {
      const resp = await dispatch('request', { req: apiManager.user.followUser(userId) }, { root: true });
      if (resp.data === userId) {
        const objectPos = state.usersToFollowList.map(user => user.id).indexOf(userId);
        const resp2 = await dispatch('request', { req: apiManager.user.getFollowingList(true) }, { root: true });
        commit('setFollowList', resp2.data);
        return commit('removeUserInUsersToFollowList', objectPos);
      }

      return state.setUsersToFollowList;
    },
    async unFollowUser({ commit, dispatch, state }, userId) {
      const resp = await dispatch('request', { req: apiManager.user.unfollowUser(userId) }, { root: true });
      if (resp.data === userId) {
        const resp2 = await dispatch('request', { req: apiManager.user.getFollowingList(true) }, { root: true });
        commit('setFollowList', resp2.data);
      }

      return state.setUsersToFollowList;
    },
    async getBrandStory({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.brandStory.follows(true) }, { root: true });
      return commit('setBrandStoriesByFollow', resp.data);
    },
  },
  getters: {
    followList: state => state.followList,
    usersToFollowList: state => state.usersToFollowList,
    brandStoriesByFollows: state => state.brandStoriesByFollows,
  },
};

export default userFollowStore;
