import './plugins/vueMq';
import Vue from 'vue';
import 'tailwindcss/tailwind.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowFromTop, faBookmark, faHeart, faSearch, faArrowLeft, faPaperclip, faList,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBookmark as fasfaBookmark, faHeart as fasfaHeart, faPencil, faUserPlus,
  faUserMinus, faCog, faFilePlus, faPenSquare, faStepForward, faFastForward, faStepBackward,
  faFastBackward, faCloudDownload, faCloudDownloadAlt, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faThLarge, faMinusCircle, faCaretLeft, faCaretRight, faToggleOff, faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VModal from 'vue-js-modal';
import VuejsDialog from 'vuejs-dialog';
import VueMasonry from 'vue-masonry-css';

import App from './App.vue';
import store from './store/index';
import router from './router';

import { AnalyticHelper } from '@/helpers/analyticHelper';
import { DownloadHelper } from '@/helpers/downloadHelper';
import polyfill from './polyfills';

import 'vue-good-table/dist/vue-good-table.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue-search-select/dist/VueSearchSelect.css';

library.add(faArrowFromTop, faBookmark, fasfaBookmark, faHeart, fasfaHeart, faSearch, faArrowLeft,
  faUserPlus, faUserMinus, faPencil, faPaperclip, faCog, faFilePlus, faList,
  faThLarge, faMinusCircle, faCaretLeft, faCaretRight, faPenSquare, faStepForward, faFastForward,
  faStepBackward, faFastBackward, faCloudDownload, faCloudDownloadAlt, faDownload, faToggleOff,
  faToggleOn);

polyfill();

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.$analytics = new AnalyticHelper();
Vue.prototype.$downloader = new DownloadHelper();

Vue.config.productionTip = false;

Vue.use(VueMasonry);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VuejsDialog);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
