import apiManager from '../../api';

const adminStore = {
  namespaced: true,
  actions: {
    async getBrandStories({ dispatch }) {
      const resp = await dispatch('request', { req: apiManager.brandStory.getAll(true) }, { root: true });
      return resp.data;
    },
    async getWhpcStories({ dispatch }) {
      const resp = await dispatch('request', { req: apiManager.whpc.getAll(true) }, { root: true });
      return resp.data;
    },
    async getComments({ dispatch }) {
      const resp = await dispatch('request', { req: apiManager.comment.getAll(true) }, { root: true });
      return resp.data;
    },
    async getProfiles({ dispatch }) {
      const resp = await dispatch('request', { req: apiManager.user.getAll(true) }, { root: true });
      return resp.data;
    },
    async getBrandStory({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.brandStory.get(id, true) }, { root: true });
      return resp.data;
    },
    async getWhpcStory({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.whpc.get(id, true) }, { root: true });
      return resp.data;
    },
    async getComment({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.comment.get(id, true) }, { root: true });
      return resp.data;
    },
    async getProfile({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.user.get(id, true) }, { root: true });
      return resp.data;
    },
    async updateBrandStory({ dispatch }, commentObject) {
      const resp = await dispatch('request', { req: apiManager.brandStory.put(commentObject) }, { root: true });
      return resp.data;
    },
    async updateCommentforBrandStory({ dispatch }, commentObject) {
      const resp = await dispatch('request', { req: apiManager.comment.put(commentObject) }, { root: true });
      return resp.data;
    },
    async updateWhpcStory({ dispatch }, whpcObject) {
      const resp = await dispatch('request', { req: apiManager.whpc.put(whpcObject) }, { root: true });
      return resp.data;
    },
    async updateUserProfile({ dispatch }, userProfileObject) {
      const resp = await dispatch('request', { req: apiManager.user.put(userProfileObject) }, { root: true });
      return resp.data;
    },
  },
};

export default adminStore;
