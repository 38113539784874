import baseAPI from './baseAPI';

export default class Search extends baseAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/search';
  }

  // search(searchString, isGuildVisible, cached) {
  search(args, cached) {
    const params = args;

    if (cached) {
      params.unique = Math.round(+new Date() / 1000);
    }

    return this.axiosObject.get(this.apiBaseName, { params });
  }
}
