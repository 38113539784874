import baseCRUDAPI from './baseCRUDAPI';

export default class User extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/user';
  }

  followUser(userIdToFollow) {
    return this.axiosObject.post(`${this.apiBaseName}/follow/${userIdToFollow}`);
  }

  unfollowUser(userIdToFollow) {
    return this.axiosObject.delete(`${this.apiBaseName}/follow/${userIdToFollow}`);
  }

  getFollowingList(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/follow${cachedParams}`);
  }

  getOthersToFollow(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/follow/others${cachedParams}`);
  }

  async downloadUserReport() {
    const date = new Date().toLocaleDateString().replace(/\//g, '-');
    const { data } = await this.axiosObject.request({
      url: `${this.apiBaseName}/report`,
      method: 'GET',
      responseType: 'blob',
    });
    const objectUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', `LI Guide user report ${date}.xlsx`);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
