<script>
export default {
  methods: {
    getImage(path) {
      if (path) {
        return process.env.VUE_APP_BASE_ASSETS_IMAGES + path;
      }
      return '';
    },
    isOnLogoutPage() {
      return this.$route.name === 'logout';
    },
  },
};
</script>
