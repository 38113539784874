const buttonSubmitStore = {
  namespaced: true,
  state: {
    submitting: false,
    message: false,
  },
  mutations: {
    toggleStatus: (state, toggle) => {
      state.submitting = toggle;
    },
    toggleMessage: (state, toggle) => {
      state.message = toggle;
    },
  },
  actions: {
    submit({ commit }) {
      commit('toggleMessage', false);
      return commit('toggleStatus', true);
    },
    done({ commit }) {
      commit('toggleMessage', false);
      return commit('toggleStatus', false);
    },
    badRequest({ commit }) {
      commit('toggleStatus', false);
      return commit('toggleMessage', true);
    },
  },
  getters: {
    submitting: state => state.submitting,
    message: state => state.message,
  },
};

export default buttonSubmitStore;
