import apiManager from '../../api';

const brandStoryStore = {
  namespaced: true,
  state: {
    brandStories: [],
    currentBrandStory: null,
    currentBrandStoryComments: [],
    favoriteBrandStories: [],
    brandStoryAttachments: [],
    approvedBrandStories: [],
  },
  mutations: {
    setBrandStories: (state, brandStories) => { state.brandStories = brandStories; },
    setCurrentBrandStory: (state, currentBrandStory) => {
      state.currentBrandStory = currentBrandStory;
    },
    setCurrentBrandStoryComments: (state, currentBrandStoryComments) => {
      state.currentBrandStoryComments = currentBrandStoryComments;
    },
    setFavoriteBrandStories: (state, favoriteBrandStories) => {
      state.favoriteBrandStories = favoriteBrandStories;
    },
    setBrandStoryAttachments: (state, brandStoryAttachments) => {
      state.brandStoryAttachments = brandStoryAttachments;
    },
    setApprovedBrandStories: (state, approvedBrandStories) => {
      state.approvedBrandStories = approvedBrandStories;
    },
  },
  actions: {
    async getBrandStories({ commit, dispatch }) {
      const resp = await dispatch('request', {
        req: apiManager.brandStory.getAll(true),
      }, { root: true });
      return commit('setBrandStories', resp.data);
    },
    async getApprovedBrandStories({ commit, dispatch }) {
      const resp = await dispatch('request', {
        req: apiManager.brandStory.approved(true),
      }, { root: true });
      return commit('setApprovedBrandStories', resp.data);
    },
    async getBrandStory({ commit, dispatch }, id) {
      const resp = await dispatch('request', {
        req: apiManager.brandStory.get(id, true),
      }, { root: true });
      return commit('setCurrentBrandStory', resp.data);
    },
    async submitBrandStory({ dispatch }, commentObject) {
      const resp = await dispatch('request', {
        req: apiManager.brandStory.post(commentObject),
      }, { root: true });
      return resp.data;
    },
    setCurrentBrandStory({ commit }, brandStoryObject) {
      return commit('setCurrentBrandStory', brandStoryObject);
    },
    async getCommentsForBrandStory({ commit, state, dispatch }, id) {
      state.currentBrandStoryComments = [];
      const resp = await dispatch('request', {
        req: apiManager.brandStory.comments(id),
      }, { root: true });
      return commit('setCurrentBrandStoryComments', resp.data);
    },
    async submitCommentForBrandStory({ commit, dispatch }, commentObject) {
      await dispatch('request', {
        req: apiManager.comment.post(commentObject),
      }, { root: true });
      const resp = await dispatch('request', {
        req: apiManager.brandStory.comments(commentObject.brandStoryId, true),
      }, { root: true });
      return commit('setCurrentBrandStoryComments', resp.data);
    },
    async saveCommentForBrandStory({ commit, dispatch }, commentObject) {
      await dispatch('request', {
        req: apiManager.comment.full(commentObject),
      }, { root: true });
      const resp = await dispatch('request',
        { req: apiManager.brandStory.comments(commentObject.brandStoryId, true) }, { root: true });
      return commit('setCurrentBrandStoryComments', resp.data);
    },
    async getFavoriteBrandStories({ commit, dispatch }) {
      const resp = await dispatch('request', {
        req: apiManager.brandStory.favorited(true),
      }, { root: true });
      return commit('setFavoriteBrandStories', resp.data);
    },
    async getAttachmentsForBrandStory({ commit, dispatch }, id) {
      const resp = await dispatch('request', {
        req: apiManager.brandStoryAttachment.attachments(id),
      }, { root: true });

      const attachments = await Promise.all(resp.data.map(attachment => dispatch('request', {
        req: apiManager.asset.get(attachment.attachmentAssetId),
      }, { root: true })));

      commit('setBrandStoryAttachments', attachments.map(a => a.data));
    },
    async removeAttachmentForBrandStory({ dispatch }, assetObject) {
      const resp = await dispatch('request', {
        req: apiManager.brandStoryAttachment.delete(assetObject.storyId, assetObject.assetId),
      }, { root: true });
      await dispatch('request', {
        req: apiManager.asset.delete(assetObject.assetId),
      }, { root: true });
      return resp.data;
    },
  },
  getters: {
    brandStories: state => state.brandStories,
    currentBrandStory: state => state.currentBrandStory,
    currentBrandStoryComments: state => state.currentBrandStoryComments,
    favoriteBrandStories: state => state.favoriteBrandStories,
    brandStoryAttachments: state => state.brandStoryAttachments,
    approvedBrandStories: state => state.approvedBrandStories,
  },
};

export default brandStoryStore;
