var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showNavigationBar,
          expression: "showNavigationBar",
        },
      ],
      class: {
        navbar: !_vm.onHomePage,
        navbarHome: _vm.onHomePage,
        active: _vm.isActive,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "navbar-toggle",
          class: { active: _vm.isActive },
          on: {
            click: function ($event) {
              return _vm.toggleMenu()
            },
          },
        },
        [_c("span"), _c("span"), _c("span")]
      ),
      _c(
        "div",
        { staticClass: "routerLinks", class: { active: _vm.isActive } },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.onHomePageCheck,
                  expression: "!onHomePageCheck",
                },
              ],
              staticClass: "link",
              attrs: { to: "/" },
            },
            [
              _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(this.navBar.home)),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "link", attrs: { to: "/stories" } },
            [
              _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(this.navBar.stories)),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "link", attrs: { to: "/mycommunity" } },
            [
              _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(this.navBar.community)),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "link",
              attrs: { to: { path: "profile", query: { id: _vm.profile.id } } },
            },
            [
              _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(this.navBar.profile)),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "link", attrs: { to: "/resources" } },
            [
              _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(this.navBar.resources)),
              ]),
            ]
          ),
          _vm.isAdmin
            ? _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/admin" } },
                [
                  _c("div", { staticClass: "txt" }, [
                    _vm._v(_vm._s(this.navBar.admin)),
                  ]),
                ]
              )
            : _vm._e(),
          _c("router-link", { staticClass: "link", attrs: { to: "/logout" } }, [
            _c("div", { staticClass: "txt" }, [
              _vm._v(_vm._s(this.navBar.logout)),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }