import baseAPI from './baseAPI';

export default class baseReadOnlyAPI extends baseAPI {
  getAll(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(this.apiBaseName + cachedParams);
  }

  get(guid, cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/${guid}${cachedParams}`);
  }
}
