import {
  equals, prop, compose, both,
} from 'ramda';

import apiManager from '../../api';

import { setSessionItem, getSessionItem, clearCookies } from '../../utils';

const tokenName = process.env.VUE_APP_TOKEN_NAME;
const roleName = process.env.VUE_APP_ROLE_NAME;

const setSessionToken = setSessionItem(tokenName);
const setSessionRole = setSessionItem(roleName);

const getStateToken = state => state.token;
const getStateRole = state => state.role;

// validateToken :: token -> bool
const isTokenValid = token => token !== null && token !== undefined && token !== '' && token !== 'null';

const isAuthSet = state => state.authSet;

// getToken :: state -> token
const getToken = state => getStateToken(state) || getSessionItem(tokenName);
const getRole = state => getStateRole(state) || getSessionItem(roleName);

const checkRole = role => compose(equals(role), prop('role'));

const authStore = {
  namespaced: true,
  state: {
    token: null,
    role: null,
    authSet: false,
  },
  mutations: {
    setToken: (state, token) => { state.token = token; },
    setRole: (state, role) => { state.role = role; },
    setAuthSet: (state, bool) => { state.authSet = bool; },
  },
  actions: {
    async updateAuth({ commit }, { token, role } = {}) {
      commit('setToken', token);
      setSessionToken(token);
      commit('setRole', role);
      setSessionRole(role);
      apiManager.setAuthHeader(token);
      return commit('setAuthSet', true);
    },
    async checkLocalAuth({
      state, getters, dispatch,
    }) {
      if (!getters.isAuthenticated) dispatch('updateAuth', { token: getToken(state), role: getRole(state) });
    },
    reset({ dispatch }) {
      clearCookies();
      dispatch('updateAuth', { token: null, role: null });
    },
  },
  getters: {
    isAuthenticated: both(isAuthSet, compose(isTokenValid, getToken)),
    isAdmin: (state, getters) => getters.isAuthenticated && checkRole('ADMIN')(state),
    isUser: (state, getters) => getters.isAuthenticated && checkRole('USER')(state),
    isGuild: (state, getters) => getters.isAuthenticated && checkRole('GUILD')(state),
    isGuildVisible: (state, getters) => getters.isAuthenticated && (checkRole('GUILD')(state) || checkRole('ADMIN')(state)),
  },
};

export default authStore;
