import Authenticate from './authenticate';
import BrandStory from './brandStory';
import BrandStoryStatus from './brandStoryStatus';
import BrandStoryLike from './brandStoryLike';
import BrandStoryFavorite from './brandStoryFavorite';
import BrandStoryAttachment from './brandStoryAttachment';
import BusinessArea from './businessArea';
import EffortlessTenet from './effortlessTenet';
import ResourceDocument from './resourceDocument';
import ResourceVideo from './resourceVideo';
import EngagementLevel from './engagementLevel';
import Comment from './comment';
import User from './user';
import Whpc from './whpc';
import Asset from './asset';
import CommentStatus from './commentStatus';
import WhpcStoryStatus from './whpcStoryStatus';
import Search from './search';
import UserProfileImageStatus from './userProfileImageStatus';
import Carousel from './carousel';
import Position from './position';
import AnalyticTypes from './analyticTypes';
import Analytic from './analytic';

class ApiManager {
  constructor() {
    // BASE_API_URL is a webpack variable defined per environment for the API path
    // .env, .env.staging, .env.preprod, .env.production
    this.baseURLPath = process.env.VUE_APP_BASE_API_URL;

    // Authenticate object
    this.authenticate = new Authenticate(this.baseURLPath);

    // CRUD based objects
    this.businessArea = new BusinessArea(this.baseURLPath);
    this.effortlessTenet = new EffortlessTenet(this.baseURLPath);
    this.brandStory = new BrandStory(this.baseURLPath);
    this.brandStoryStatus = new BrandStoryStatus(this.baseURLPath);
    this.brandStoryLike = new BrandStoryLike(this.baseURLPath);
    this.brandStoryFavorite = new BrandStoryFavorite(this.baseURLPath);
    this.brandStoryAttachment = new BrandStoryAttachment(this.baseURLPath);
    this.resourceDocument = new ResourceDocument(this.baseURLPath);
    this.resourceVideo = new ResourceVideo(this.baseURLPath);
    this.user = new User(this.baseURLPath);
    this.engagementLevel = new EngagementLevel(this.baseURLPath);
    this.comment = new Comment(this.baseURLPath);
    this.whpc = new Whpc(this.baseURLPath);
    this.asset = new Asset(this.baseURLPath);
    this.commentStatus = new CommentStatus(this.baseURLPath);
    this.whpcStoryStatus = new WhpcStoryStatus(this.baseURLPath);
    this.search = new Search(this.baseURLPath);
    this.userProfileImageStatus = new UserProfileImageStatus(this.baseURLPath);
    this.carousel = new Carousel(this.baseURLPath);
    this.position = new Position(this.baseURLPath);
    this.analyticTypes = new AnalyticTypes(this.baseURLPath);
    this.analytic = new Analytic(this.baseURLPath);
  }

  setAuthHeader(auth) {
    this.user.setAuthorizationHeader(auth);
    this.businessArea.setAuthorizationHeader(auth);
    this.effortlessTenet.setAuthorizationHeader(auth);
    this.brandStory.setAuthorizationHeader(auth);
    this.brandStoryLike.setAuthorizationHeader(auth);
    this.brandStoryFavorite.setAuthorizationHeader(auth);
    this.brandStoryStatus.setAuthorizationHeader(auth);
    this.brandStoryAttachment.setAuthorizationHeader(auth);
    this.engagementLevel.setAuthorizationHeader(auth);
    this.resourceDocument.setAuthorizationHeader(auth);
    this.resourceVideo.setAuthorizationHeader(auth);
    this.comment.setAuthorizationHeader(auth);
    this.whpc.setAuthorizationHeader(auth);
    this.asset.setAuthorizationHeader(auth);
    this.commentStatus.setAuthorizationHeader(auth);
    this.whpcStoryStatus.setAuthorizationHeader(auth);
    this.search.setAuthorizationHeader(auth);
    this.userProfileImageStatus.setAuthorizationHeader(auth);
    this.carousel.setAuthorizationHeader(auth);
    this.position.setAuthorizationHeader(auth);
    this.analyticTypes.setAuthorizationHeader(auth);
    this.analytic.setAuthorizationHeader(auth);
  }
}

const apiManager = new ApiManager();

export default apiManager;
