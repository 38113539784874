import baseCRUDAPI from './baseCRUDAPI';

export default class Whpc extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/whpc';
  }

  published(cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/published/${cachedParams}`);
  }
}
