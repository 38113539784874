<template>
  <div v-show="showProfile" class="profileContainer">
    <transition name="slide-in-top" mode="out-in">
      <component v-bind:is="toggleProfileView" @menuSwitch="menuSwitch" :profile="profile">
      </component>
    </transition>
  </div>
</template>

<script>
import ProfileCompact from '@/components/profile/ProfileCompact.vue';
import ProfileExpanded from '@/components/profile/ProfileExpanded.vue';

export default {
  name: 'ProfileContainer',
  components: {
    ProfileCompact,
    ProfileExpanded,
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(this.profile, 'points')) {
      this.$store.dispatch('engagementLevelStore/getEngagementLevels');
    }
  },
  watch: {
    profile() {
      if (Object.prototype.hasOwnProperty.call(this.profile, 'points')) {
        this.$store.dispatch('engagementLevelStore/getEngagementLevels');
      }
    },
  },
  data() {
    return {
      toggleProfileView: ProfileCompact,
    };
  },
  methods: {
    menuSwitch(name) {
      if (name === 'ProfileCompact') {
        this.toggleProfileView = ProfileExpanded;
      } else {
        this.toggleProfileView = ProfileCompact;
      }
    },
  },
  computed: {
    profile() {
      return this.$store.getters['userStore/profile'];
    },
    showProfile() {
      const yourId = this.$store.getters['profileViewStore/profile'].id;
      const profileId = this.$store.getters['userStore/profile'].id;

      if (this.$route.name === 'login'
      || (this.$route.name === 'profile' && yourId === profileId)) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
// animation slide-in-top
@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-top-enter,
.slide-in-top-enter-active {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-in-top-leave-active,
.slide-in-top-leave-to {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse;
}
</style>
