<template>
  <div
    class="imgLogoDark"
    :style="{backgroundImage:
    'url('+require('../assets/images/logoEffortlessExperienceDark.png')+')'}"
  ></div>
</template>

<script>
export default {
  name: 'LincolnEffortlessExperienceLogoDark',
};
</script>

<style lang="scss" scoped>
.imgLogoDark {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 52px;
}
</style>
