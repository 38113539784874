const navBarCopy = {
  home: {
    stories: 'WARM, HUMAN\n& PERSONALLY\nCRAFTED STORIES',
    community: 'MY COMMUNITY',
    profile: 'MY PROFILE',
    resources: 'RESOURCES',
    admin: 'ADMIN',
    logout: 'LOGOUT',
  },
  default: {
    home: 'HOME',
    stories: 'WARM, HUMAN & PERSONALLY CRAFTED STORIES',
    community: 'MY COMMUNITY',
    profile: 'MY PROFILE',
    resources: 'RESOURCES',
    admin: 'ADMIN',
    logout: 'LOGOUT',
  },
};

export default navBarCopy;
