import apiManager from '../../api';

const analyticTypeStore = {
  namespaced: true,
  state: {
    currentAnalyticType: null,
    analyticTypes: [],
  },
  mutations: {
    setCurrentAnalyticType: (state, analyticType) => { state.currentAnalyticType = analyticType; },
    setAnalyticTypes: (state, analyticTypes) => { state.analyticTypes = analyticTypes; },
  },
  actions: {
    async getAnalyticTypes({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.analyticTypes.getall() }, { root: true });
      return commit('setAnalyticTypes', resp.data);
    },
    async getAnalyticType({ commit, dispatch, getters }, analyticType) {
      if (!getters.analyticTypes || getters.analyticTypes.length === 0) {
        await dispatch('getAnalyticTypes');
      }
      const obj = getters.analyticTypes.find(element => element.analyticType === analyticType);
      commit('setCurrentAnalyticType', obj);
    },
  },
  getters: {
    analyticTypes: state => state.analyticTypes,
    currentAnalyticType: state => state.currentAnalyticType,
  },
};


export default analyticTypeStore;
