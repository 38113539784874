import apiManager from '../../api';

const positionStore = {
  namespaced: true,
  state: {
    positions: [],
  },
  mutations: {
    setPositions: (state, positions) => {
      state.positions = positions;
    },
  },
  actions: {
    async getPositions({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.position.getAll(true) }, { root: true });
      return commit('setPositions', resp.data);
    },
  },
  getters: {
    positions: state => state.positions,
  },
};

export default positionStore;
