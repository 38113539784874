<template>
  <div class="searchExpanded">
    <SearchBar />
    <div class="icon">
      <font-awesome-icon :icon="['fal', 'arrow-left']" size="2x" @click="closeSearch" />
    </div>
  </div>
</template>

<script>
import SearchBar from '../SearchBar.vue';

export default {
  name: 'SearchExpanded',
  components: {
    SearchBar,
  },
  methods: {
    closeSearch() {
      this.$emit('menuSwitch', 'SearchExpanded');
    },
  },
};
</script>

<style lang="scss" scoped>
.searchExpanded {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  // align-items: center;

  .icon {
    position: absolute;
    top: 25px;
    left: 12px;
  }
}
</style>
