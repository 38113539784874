<template>
  <div class="searchMobileContainer">
    <transition name="slide-in-right" mode="out-in">
      <component v-bind:is="toggleSearchView" @menuSwitch="menuSwitch"></component>
    </transition>
  </div>
</template>

<script>
import SearchCompact from '@/components/searchMobile/SearchCompact.vue';
import SearchExpanded from '@/components/searchMobile/SearchExpanded.vue';

export default {
  name: 'SearchMobileContainer',
  components: {
    SearchCompact,
    SearchExpanded,
  },
  data() {
    return {
      toggleSearchView: SearchCompact,
    };
  },
  methods: {
    menuSwitch(name) {
      if (name === 'SearchCompact') {
        this.toggleSearchView = SearchExpanded;
      } else {
        this.toggleSearchView = SearchCompact;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// animation slide-in-right
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.slide-in-right-enter,
.slide-in-right-enter-active {
  animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.slide-in-right-leave-active,
.slide-in-right-leave-to {
  animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse;
}
</style>
