<template>
  <div class="profileExpanded">
    <div class="topBg">
      <div @click="closeMenu" class="buttonX">X</div>
      <div class="profileGrouping">
        <div class="profileBadgeFlag" :class="engagementLevelClass">
          <EngagementLevelBadge class="badge" :badge="currentEngagementLevel" :colored="false">
          </EngagementLevelBadge>
        </div>
        <div class="profileImgWrapper">
          <router-link :to="{ path: 'profile', query: { id: profile.id }}"
                       class="circleBorderProfileImg" :class="engagementLevelClass">
            <UserImageThumbnail :thumbnailAssetId="profile.thumbnailAssetId"
                                :class="'imgProfile'" @click="cleanAndHide" />
          </router-link>
        </div>
      </div>
      <div class="txtName firstName">{{this.profile.nameFirst}}</div>
      <div class="txtName lastName">{{this.profile.nameLast}}</div>
    </div>
    <div class="pointsGrouping currentPoints" :class="{ bottomLine: pointsAway }">
      <div class="key">Your Current Points</div>
      <div class="value">{{this.profile.points}}</div>
    </div>
    <div class="pointsGrouping" v-if="pointsAway">
      <div class="key">You are {{pointsAway}} away from</div>
      <div class="value">{{nextLevelName}}</div>
    </div>
  </div>
</template>

<script>
import EngagementLevelBadge from '../engagementLevel/EngagementLevelBadge.vue';
import UserImageThumbnail from './UserImageThumbnail.vue';

export default {
  name: 'ProfileExpanded',
  props: ['profile'],
  components: {
    EngagementLevelBadge,
    UserImageThumbnail,
  },
  data() {
    return {
      downloadedImage: null,
    };
  },
  methods: {
    closeMenu() {
      this.$emit('menuSwitch', 'ProfileExpanded');
      this.$analytics.trackEventBtnProfile('ToCompactView');
    },
    cleanAndHide() {
      this.$analytics.trackEventBtnProfile('ProfileImage');
      this.$emit('menuSwitch', 'ProfileExpanded');
      const yourProfileInfo = Object.assign(
        {},
        this.$store.getters['userStore/profile'],
      );
      this.$store.dispatch('profileViewStore/setProfile', yourProfileInfo);
    },
    getNextLevel() {
      const next = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => el.pointsToUnlock >= this.profile.points);

      return next ? next[0] : null;
    },
  },
  computed: {
    engagementLevelClass() {
      return this.currentEngagementLevel
        ? this.currentEngagementLevel.name.replace(' ', '-').toLowerCase()
        : '';
    },
    currentEngagementLevel() {
      if (!this.engagementLevels) {
        return null;
      }

      const copy = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => this.profile.points >= el.pointsToUnlock);

      return copy
        ? copy.reverse()[0]
        : this.engagementLevels[this.engagementLevels.length - 1];
    },
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
    pointsAway() {
      const next = this.getNextLevel();
      return next ? next.pointsToUnlock - this.profile.points : 0;
    },
    nextLevelName() {
      const next = this.getNextLevel();
      return next ? next.name : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.profileExpanded {
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
  background-color: $ProfileExpandedBackground;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .topBg {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    z-index: -1;
    padding: 0.625em 0;

    .buttonX {
      align-self: flex-end;
      padding: 0 0.625em 0.625em;
      color: black;
      cursor: pointer;
    }

    .profileGrouping {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      width: 100%;
      height: 120px;
      padding: 0%;
      background-color: $ProfileExpandedBackgroundGroup;

      .profileBadgeFlag {
        background-image: $ProfileBadgeBackground;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        transform: translateX(-5px);

        .badge {
          transform: translateX(-5px);
          width: 80%;
        }

        &.lincoln-savvy {
          background-image: linear-gradient(
            rgb(46, 49, 146),
            rgb(15, 117, 188)
          );
        }

        &.lincoln-influential {
          background-image: linear-gradient(
            rgb(55, 34, 121),
            rgb(102, 45, 145)
          );
        }

        &.lincoln-force {
          background-image: linear-gradient(
            rgb(129, 130, 133),
            rgb(189, 190, 192)
          );
        }

        &.lincoln-legend {
          background-image: linear-gradient(rgb(36, 32, 33), rgb(88, 88, 90));
        }

        &.lincoln-notable {
          background-image: linear-gradient(
            rgb(239, 64, 54),
            rgb(239, 109, 72)
          );
        }
      }

      .profileImgWrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;

        .circleBorderProfileImg {
          background-color: rbga(0, 0, 0, 0);
          border: 3px solid $ProfileCircleBorder;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 85%;
          width: 88%;

          .imgProfile {
            background-color: rgba(0, 0, 0, 0);
            //temp profile image
            background-image: url("../../assets/images/temp/LEEDefaultProfileImage.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: inline-block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }

          &.lincoln-savvy {
            border-color: rgb(46, 49, 146);
          }

          &.lincoln-influential {
            border-color: rgb(55, 34, 121);
          }

          &.lincoln-force {
            border-color: rgb(129, 130, 133);
          }

          &.lincoln-legend {
            border-color: rgb(36, 32, 33);
          }

          &.lincoln-notable {
            border-color: rgb(239, 64, 54);
          }
        }
      }

      .profileGrouping > * {
        align-self: center;
      }
    }
  }

  .txtName {
    text-transform: uppercase;
    line-height: 1;
    padding: 0;
    align-self: center;

    &.firstName {
      @include font-size(2.375rem);
      font-weight: $bold;
      margin-top: 3%;
    }

    &.lastName {
      @include font-size(1.75rem);
    }
  }

  .pointsGrouping {
    width: 86%;
    margin-bottom: 5%;

    &.currentPoints {
      padding-top: 10%;

      .value {
        @include font-size(2.375rem);
        font-weight: $bold;
      }
    }

    &.bottomLine {
      padding-bottom: 3%;
      border-bottom: 0.5px solid white;
    }

    .key {
      flex: 1;
      @include font-size(0.875rem);
      text-align: left;
    }
    .value {
      @include font-size(1.375rem);
      font-weight: $bold;
      text-transform: uppercase;
      flex: 1;
    }
  }
  .pointsGrouping > * {
    align-self: center;
  }
}
.profileExpanded > * {
  align-self: center;
}
</style>
