import baseCRUDAPI from './baseCRUDAPI';

export default class Analytic extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = `${baseURLPath}/api/analytic`;
  }


  add(analytic) {
    const resp = this.axiosObject.post(`${this.apiBaseName}/add`, analytic);
    return resp;
  }

  async startSession(userId) {
    const resp = await this.axiosObject.post(`${this.apiBaseName}/startSession/${userId}`);
    return resp;
  }

  async updateSession(sessionId) {
    const resp = await this.axiosObject.post(`${this.apiBaseName}/updateSession/${sessionId}`);
    return resp;
  }
}
