import apiManager from '../../api';
import authStore from './authStore';

import { setSessionItem, getSessionItem } from '../../utils';

const profileName = process.env.VUE_APP_USER_NAME;

const userStore = {
  namespaced: true,
  modules: {
    authStore,
  },
  state: {
    profile: {
      nameFirst: '',
      nameLast: '',
      namePreferred: '',
      pointsAway: 0,
      engagementLevel: null,
      position: null,
    },
  },
  mutations: {
    setProfile: (state, profile) => {
      setSessionItem(profileName, JSON.stringify(profile));
      state.profile = profile;
    },
  },
  actions: {
    async login({ dispatch }, userLoginObject) {
      const resp = await dispatch('request',
        { req: apiManager.authenticate.token(userLoginObject), block: true }, { root: true });
      const { data } = resp;
      const returned = await dispatch('authStore/updateAuth', { token: data.token, role: data.role });
      return returned;
    },
    async logout({ dispatch }) {
      return dispatch('reset', {}, { root: true });
    },
    async checkLogin({ commit, dispatch }) {
      const storedProfile = JSON.parse(getSessionItem(profileName));
      if (storedProfile !== null) {
        commit('setProfile', storedProfile);
      }
      return dispatch('authStore/checkLocalAuth');
    },
    async getUser({ commit, dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.user.get(id, true) }, { root: true });
      return commit('setProfile', resp.data);
    },
    async checkUser({ dispatch }, uniqueIdentifier) {
      const resp = await dispatch('request', { req: apiManager.authenticate.getUserByCdsId(uniqueIdentifier, true) }, { root: true });
      if (resp.status === 404) {
        return resp;
      }
      return resp.data;
    },
    async getUserByStarsID({ dispatch }, uniqueIdentifier) {
      const resp = await dispatch('request', { req: apiManager.authenticate.getUserByStarsId(uniqueIdentifier, true) }, { root: true });
      if (resp.status === 404) {
        return resp;
      }
      return resp.data;
    },
    async createUser({ dispatch }, userObject) {
      const resp = await dispatch('request', { req: apiManager.authenticate.createUser(userObject) }, { root: true });
      return resp.data;
    },
    async updateProfile({ commit, dispatch }, profileObject) {
      await dispatch('request', { req: apiManager.user.put(profileObject) }, { root: true });
      commit('setProfile', profileObject);
    },
    async getPoints({ commit, dispatch, state }) {
      const resp = await dispatch('request', { req: apiManager.user.get(state.profile.id, true) }, { root: true });
      state.profile.points = resp.data.points;
      commit('setProfile', state.profile);

      return state.profile.points;
    },
    async oAuthAuthorizeURL({ dispatch }) {
      const resp = await dispatch('request', { req: apiManager.authenticate.oAuthAuthorize() }, { root: true });
      return resp.data;
    },
    async oAuthToken({ dispatch }, authOject) {
      const resp = await dispatch('request', { req: apiManager.authenticate.oAuthToken(authOject) }, { root: true });
      return resp.data;
    },
  },
  getters: {
    isAdmin: (state, getters) => getters['authStore/isAdmin'],
    isUser: (state, getters) => getters['authStore/isUser'],
    isGuild: (state, getters) => getters['authStore/isGuild'],
    isGuildVisible: (state, getters) => getters['authStore/isGuildVisible'],
    isAuthenticated: (state, getters) => getters['authStore/isAuthenticated'],
    profile: state => state.profile,
    userId: state => state.profile.id,
  },
};

export default userStore;
