<template>
    <div></div>
</template>

<script>

export default {
  name: 'ActivityTracker',
  data() {
    return {
      interval: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['userStore/isAuthenticated'];
    },
    userId() {
      return this.$store.getters['userStore/userId'];
    },
    sessionId() {
      return this.$store.getters['analyticStore/sessionId'];
    },
  },
  methods: {
    lastNavDiff() {
      const curdtm = new Date();
      return curdtm - this.$store.getters['analyticStore/lastNav'];
    },
    isInactive() {
      return this.lastNavDiff() > 1000 * 60 * 30; // 30 minutes
    },
    startActivityTimer() {
      this.clearActivityTimer();
      this.interval = setInterval(() => {
        if (this.sessionId !== undefined) {
          this.$store.dispatch('analyticStore/updateSession');
        }

        if (this.isInactive() && this.sessionId) {
          this.$store.dispatch('analyticStore/clearSession');
        } else if (!this.isInactive() && !this.sessionId) {
          this.startSession();
        }
      },
      60000);
    },
    clearActivityTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    startSession() {
      this.$store.dispatch('analyticStore/startSession', this.userId);
    },
    clearSession() {
      this.$store.dispatch('analyticStore/clearSession');
    },
  },
  watch: {
    sessionId() {
      if (!this.isInactive() && this.sessionId !== undefined) {
        this.startActivityTimer();
      }
    },
    isAuthenticated() {
      if (!this.isAuthenticated) {
        this.clearActivityTimer();
      } else if (this.isAuthenticated && this.userId !== undefined) {
        Promise.all([this.startSession()]);
      }
    },
    userId() {
      if (this.isAuthenticated && this.userId !== undefined) {
        Promise.all([this.startSession()]);
      }
    },
  },
};
</script>
