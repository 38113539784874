import baseAPI from './baseAPI';

export default class Carousel extends baseAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/carousel';
  }

  get() {
    return this.axiosObject.get(`${this.apiBaseName}`, {
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  update(carouselData) {
    return this.axiosObject.put(`${this.apiBaseName}`, carouselData, {
      headers: {
        'Content-type': 'application/json',
      },
    });
  }
}
