import baseCRUDAPI from './baseCRUDAPI';

export default class ResourceVideo extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/resourceVideo';
  }

  getAllTryThisVideos() {
    return this.axiosObject.get(`${this.apiBaseName}/GetAllTryThisVideos`);
  }

  getAllGuildVideos() {
    return this.axiosObject.get(`${this.apiBaseName}/GetAllGuildVideos`);
  }

  getVideosByType(videoType) {
    return this.axiosObject.get(`${this.apiBaseName}/GetVideosByType/${videoType}`);
  }

  async toggleEnabled(id) {
    const resp = await this.axiosObject.put(`${this.apiBaseName}/ToggleEnabled/${id}`);
    return resp;
  }
}
