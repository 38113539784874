import baseCRUDAPI from './baseCRUDAPI';

export default class AnalyticTypes extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = `${baseURLPath}/api/analyticType`;
  }

  getall() {
    return this.axiosObject.get(`${this.apiBaseName}/getall`);
  }

  get(id) {
    return this.axiosObject.get(`${this.apiBaseName}/get/${id}`);
  }
}
