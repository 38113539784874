import apiManager from '../../api';

const userProfileImageStatusStore = {
  namespaced: true,
  state: {
    statuses: [],
  },
  mutations: {
    setProfileImageStatuses: (state, statuses) => {
      state.statuses = statuses;
    },
  },
  actions: {
    async getProfileImageStatuses({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.userProfileImageStatus.getAll(true) }, { root: true });
      return commit('setProfileImageStatuses', resp.data);
    },
  },
  getters: {
    statuses: state => state.statuses,
  },
};

export default userProfileImageStatusStore;
