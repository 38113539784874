<template>
  <img :src="image" :class="cssClass"/>
</template>
<script>
/* eslint-disable-next-line global-require */
const defaultImage = require('../../assets/images/temp/LEEDefaultProfileImage.png');

export default {
  name: 'UserImageThumbnail',
  props: ['thumbnailAssetId'],
  data() {
    return {
      image: defaultImage,
      cssClass: 'img sizeMedium',
    };
  },
  watch: {
    async thumbnailAssetId(newThumbnailAssetId) {
      await this.setImage(newThumbnailAssetId);
    },
  },
  async created() {
    this.setImage(this.thumbnailAssetId);
  },
  methods: {
    async setImage(thumbnailAssetId) {
      if (thumbnailAssetId) {
        try {
          const asset = await this.$store.dispatch(
            'assetStore/cachedAsset',
            thumbnailAssetId,
          );
          if (asset) {
            const imageUrl = window.URL.createObjectURL(new Blob([asset.data]));
            this.image = imageUrl;
          }
        } catch {
          this.image = defaultImage;
        }
      } else {
        this.image = defaultImage;
      }
    },
  },
};
/* eslint-enable global-require */
</script>
<style lang="scss" scoped>
  .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-color: $LincolnGrayLighter;
    &.sizeFull

  {
    height: 100px;
  }

  &.sizeMedium {
    width: 80px;
    height: 80px;
  }

  &.sizeSmall {
    width: 50px;
    height: 50px;
  }
  }
</style>
