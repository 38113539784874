var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isOnLogoutPage(),
          expression: "!isOnLogoutPage()",
        },
      ],
      staticClass: "footer",
    },
    [
      _c("div", { staticClass: "rightSideInfo" }, [
        _c("div", [_vm._v(_vm._s(this.footerInfo.title))]),
        _c("div", [_vm._v(_vm._s(this.footerInfo.email))]),
        _c("br"),
        _c("div", [_vm._v(_vm._s(this.footerInfo.titleSub))]),
        _c("div", [_vm._v(_vm._s(this.footerInfo.telephone))]),
        _c("br"),
        _c("div", [_vm._v(_vm._s(this.footerInfo.lastLine))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }