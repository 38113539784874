<template>
  <div id="app">
    <div class="mainWrapper">
      <ActivityTracker></ActivityTracker>
      <TopBar />
      <NavigationBar />
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import NavigationBar from './components/NavigationBar.vue';
import Footer from './components/Footer.vue';
import ActivityTracker from './components/analytic/activityTracker.vue';

export default {
  name: 'app',
  components: {
    TopBar,
    NavigationBar,
    Footer,
    ActivityTracker,
  },
  mounted: function mounted() {
    if (this.isIE()) {
      const thisPage = window.location.href;
      const message = '<p>Thank you for being a part of The Lincoln Effortless Experience. '
        + 'The Internet Explorer browser you are using is no longer supported. '
        + 'For the best experience, please use another browser such as '
        + `Chrome, Edge, Firefox, or Safari.</p> <a href="${thisPage}">${thisPage}</a>`;

      this.$dialog
        .confirm(message, {
          html: true,
          okText: 'OK',
          cancelText: null,
          customClass: 'hideCancel',
        });
    }
  },
  methods: {
    isIE() {
      const { userAgent } = window.navigator;

      return userAgent.indexOf('MSIE') >= 0 || !!userAgent.match(/Trident.*rv:11\./);
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

.mainWrapper {
  padding-bottom: 190px;
}

body {
  margin: 0;
  font-family: $ProximaNovaReg;
  font-style: normal;
  @include font-size(1rem);

  .dg-btn--ok {
    border-color: $LincolnOrange;
    color: $LincolnOrange;
  }

  .dg-btn--cancel {
    background-color: $LincolnOrange;
  }
}

.content-wrapper {
  padding: 0 15px;
  max-width: 1640px;
  margin: 0 auto;
}
#modals-container {
  .v--modal-overlay {
    background: rgba($color: #000000, $alpha: 0.75);

    .v--modal-box {
      border-radius: 20px;

      label {
        overflow: hidden;

        /*TODO uncomment span after all forms are styled and placeholders are added*/
        span {
          display: none;
        }
      }

      input {
        @include font-size(1rem);
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;

        &:disabled {
          background: #eee;
          color: lightslategray;
        }
      }

      textarea {
        @include font-size(1rem);
        padding: 15px;
        box-sizing: border-box;
      }

      button {
        font-family: $ProximaNovaReg;
      }
    }
  }
}
.hideCancel {
  .dg-btn--cancel {
    display: none;
  }

  .dg-content {
    word-wrap: break-word;

    a {
      color: $LincolnOrange;
    }
  }
}
</style>
