import axios from 'axios';

export default class baseAPI {
  constructor(baseURLPath) {
    this.axiosObject = axios.create({
      baseURL: baseURLPath,
    });
    this.axiosObject.interceptors.request.use(config => config);
    this.axiosObject.interceptors.response.use(
      (resp) => {
        if (resp.status === 401) {
          return resp;
        }
        return resp;
      },
      (err) => {
        if (err.response.status === 401) {
          return Promise.reject(err);
        }
        return Promise.reject(err);
      },
    );
    this.apiBaseName = '';
  }

  setWithCredentials() {
    this.axiosObject.defaults.withCredentials = true;
  }

  setAuthorizationHeader(authToken) {
    this.axiosObject.defaults.headers.common.Authorization = authToken;
  }
}
