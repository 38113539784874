import apiManager from '../../api';

const brandStoryFavoriteStore = {
  namespaced: true,
  actions: {
    async getFavoriteStatus({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.brandStoryFavorite.get(id, true) }, { root: true });
      return resp.data;
    },
    async setFavoriteStatus({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.brandStoryFavorite.post({ brandStoryId: id }) }, { root: true });
      return resp.data;
    },
  },
};

export default brandStoryFavoriteStore;
