import apiManager from '../../api';

const businessAreaStore = {
  namespaced: true,
  state: {
    currentArea: null,
    businessAreas: [],
  },
  mutations: {
    setCurrentArea: (state, currentArea) => {
      state.currentArea = currentArea;
    },
    setBusinessAreas: (state, businessAreas) => {
      state.businessAreas = businessAreas;
    },
  },
  actions: {
    setCurrentArea({ commit }, area) {
      return commit('setCurrentArea', area);
    },
    async getBusinessAreas({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.businessArea.getAll(true) }, { root: true });
      return commit('setBusinessAreas', resp.data);
    },
    reset({ commit }) {
      return commit('setCurrentArea', null);
    },
  },
  getters: {
    currentArea: state => state.currentArea,
    businessAreas: state => state.businessAreas,
  },
};

export default businessAreaStore;
