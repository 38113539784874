import apiManager from '../../api';

const engagementLevelStore = {
  namespaced: true,
  state: {
    engagementLevels: [],
  },
  mutations: {
    setEngagementLevels: (state, engagementLevels) => {
      state.engagementLevels = engagementLevels;
    },
  },
  actions: {
    async getEngagementLevels({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.engagementLevel.getAll(true) }, { root: true });
      return commit('setEngagementLevels', resp.data);
    },
  },
  getters: {
    engagementLevels: state => state.engagementLevels,
  },
};

export default engagementLevelStore;
