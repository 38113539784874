<template>
  <div
    class="imgLogo"
    :style="{backgroundImage: 'url('+require('../assets/images/logoEffortlessExperience.png')+')'}"
  ></div>
</template>

<script>
export default {
  name: 'LincolnEffortlessExperienceLogo',
};
</script>

<style lang="scss" scoped>
.imgLogo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 52px;
}
</style>
