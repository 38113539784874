import { curry } from 'ramda';
// getSessionItem :: string -> string
const getSessionItem = itemName => sessionStorage.getItem(itemName);

// setSessionItem :: string -> item -> ??
const setSessionItem = curry((name, item) => sessionStorage.setItem(name, item));

// clearSessionItem :: string -> string
const clearSessionItem = itemName => sessionStorage.removeItem(itemName);

const clearCookies = () => {
  const clearCookie = (name) => {
    document.cookie = `${name}=; expires=${new Date(0)}; domain=dealerconnection.com; path=/`;
  };

  document.cookie.split(',').forEach((cookie) => {
    clearCookie(cookie.split('=')[0]);
  });
};

export {
  getSessionItem,
  setSessionItem,
  clearSessionItem,
  clearCookies,
};
