import apiManager from '../../api';

const tenetStore = {
  namespaced: true,
  state: {
    currentTenet: null,
    effortlessTenets: [],
  },
  mutations: {
    setCurrentTenet: (state, currentTenet) => {
      state.currentTenet = currentTenet;
    },
    setEffortlessTenets: (state, effortlessTenets) => {
      state.effortlessTenets = effortlessTenets;
    },
  },
  actions: {
    setCurrentTenet({ commit }, tenetObject) {
      return commit('setCurrentTenet', tenetObject);
    },
    async getEffortlessTenets({ commit, dispatch }) {
      const resp = await dispatch('request', { req: apiManager.effortlessTenet.getAll(true) }, { root: true });
      return commit('setEffortlessTenets', resp.data);
    },
    reset({ commit }) {
      return commit('setCurrentTenet', null);
    },
  },
  getters: {
    currentTenet: state => state.currentTenet,
    effortlessTenets: state => state.effortlessTenets,
  },
};

export default tenetStore;
