var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "imgLogoDark",
    style: {
      backgroundImage:
        "url(" +
        require("../assets/images/logoEffortlessExperienceDark.png") +
        ")",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }