/* eslint-disable import/no-cycle */
import baseCRUDAPI from './baseCRUDAPI';

export default class BrandStoryAttachment extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/brandStoryAttachment';
  }

  attachments(brandStoryId, cached) {
    const path = '/brandStory';
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${path}/${brandStoryId}/attachments${cachedParams}`);
  }

  delete(brandStoryId, assetId) {
    const path = '/brandStory';
    return this.axiosObject.delete(`${path}/${brandStoryId}/attachment/${assetId}`);
  }
}
