import apiManager from '../../api';

const resourceStore = {
  namespaced: true,
  state: {
    documents: [],
    videos: [],
    guildVideos: [],
    resourceLibrary: [],
    trythisLibrary: [],
  },
  mutations: {
    setDocuments: (state, documents) => { state.documents = documents; },
    setVideos: (state, videos) => { state.videos = videos; },
    setGuildVideos: (state, guildVideos) => { state.guildVideos = guildVideos; },
    setTryThisVideos: (state, trythisLibrary) => {
      state.trythisLibrary = trythisLibrary;
    },
    setResourceLibraryVideos: (state, resourceLibrary) => {
      state.resourceLibrary = resourceLibrary;
    },
  },
  actions: {
    async getDocuments({ commit, dispatch }, resourceType) {
      commit('setDocuments', []);
      const resp = await dispatch('request', { req: apiManager.resourceDocument.getAll(resourceType, true) }, { root: true });
      return commit('setDocuments', resp.data);
    },
    async getDocument({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.resourceDocument.getDocument(id, true) }, { root: true });
      return resp.data;
    },
    async submitDocument({ commit, dispatch }, object) {
      await dispatch('request', { req: apiManager.resourceDocument.post(object) }, { root: true });
      const resp = await dispatch('request', { req: apiManager.resourceDocument.getAll(object.documentType, true) }, { root: true });
      return commit('setDocuments', resp.data);
    },
    async getAllCarouselVideos({ commit, dispatch }) {
      commit('setVideos', []);
      const paramResourceType = 'Carousel';
      const resp = await dispatch('request', { req: apiManager.resourceVideo.getVideosByType(paramResourceType, true) }, { root: true });
      return commit('setVideos', resp.data);
    },
    async getVideos({ commit, dispatch }, resourceType) {
      commit('setVideos', []);
      let paramResourceType = resourceType;
      if (!paramResourceType) {
        paramResourceType = 'All';
      }
      const resp = await dispatch('request', { req: apiManager.resourceVideo.getVideosByType(paramResourceType, true) }, { root: true });
      return commit('setVideos', resp.data);
    },
    async getResourceLibraryVideos({ commit, dispatch }) {
      commit('setResourceLibraryVideos', []);
      const resp = await dispatch('request', { req: apiManager.resourceVideo.getVideosByType('RESOURCE_LIBRARY', true) }, { root: true });
      return commit('setResourceLibraryVideos', resp.data);
    },
    async getTryThisVideos({ commit, dispatch }) {
      commit('setTryThisVideos', []);
      const resp = await dispatch('request', { req: apiManager.resourceVideo.getAllTryThisVideos(true) }, { root: true });
      return commit('setTryThisVideos', resp.data);
    },
    async getGuildVideos({ commit, dispatch }) {
      commit('setGuildVideos', []);
      const resp = await dispatch('request', { req: apiManager.resourceVideo.getAllGuildVideos(true) }, { root: true });
      return commit('setGuildVideos', resp.data);
    },
    async submitVideo({ dispatch }, object) {
      await dispatch('request', { req: apiManager.resourceVideo.post(object) }, { root: true });
      return dispatch('getVideos', object.videoType);
    },
    async deleteVideo({ dispatch }, objVideo) {
      return dispatch('request', {
        req: apiManager.resourceVideo.delete(objVideo.videoId),
      }, {
        root: true,
      }).then(() => {
        if (objVideo.assetId != null) {
          return dispatch('request', { req: apiManager.asset.delete(objVideo.assetId) }, { root: true });
        }

        return Promise.resolve();
      });
    },
    async deleteDocument({ dispatch }, objDoc) {
      return dispatch('request', {
        req: apiManager.resourceDocument.delete(objDoc.documentId),
      }, {
        root: true,
      }).then(async () => {
        const deleteAsset = apiManager.asset.delete;
        const requests = [];
        if (objDoc.docAssetId != null) {
          requests.push(dispatch('request', {
            req: deleteAsset(objDoc.docAssetId),
          }, {
            root: true,
          }));
        }
        if (objDoc.thumbnailAssetId != null) {
          requests.push(dispatch('request', {
            req: deleteAsset(objDoc.thumbnailAssetId),
          }, {
            root: true,
          }));
        }

        return Promise.all(requests);
      });
    },
  },
  getters: {
    documents: state => state.documents,
    videos: state => state.videos,
    guildVideos: state => state.guildVideos,
    trythisLibrary: state => state.trythisLibrary,
    resourceLibrary: state => state.resourceLibrary,
  },
};

export default resourceStore;
