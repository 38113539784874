/* eslint-disable */

import router from '../router';

export class AnalyticHelper {

    constructor() {

			this.appInstance = null;
			this.addOneTrustHelperExtension();
			this.allowCookies = window.otFordHelper.groupConsent(6);

			if(this.allowCookies) {
			//Application Insight Integration
			var appInsights = window.appInsights || function (a) {
				function b(a) { c[a] = function () { var b = arguments; c.queue.push(function () { c[a].apply(c, b) }) } } var c = { config: a }, d = document, e = window; setTimeout(function () { var b = d.createElement("script"); b.src = a.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js", d.getElementsByTagName("script")[0].parentNode.appendChild(b) }); try { c.cookie = d.cookie } catch (a) { } c.queue = []; for (var f = ["Event", "Exception", "Metric", "PageView", "Trace", "Dependency"]; f.length;)b("track" + f.pop()); if (b("setAuthenticatedUserContext"), b("clearAuthenticatedUserContext"), b("startTrackEvent"), b("stopTrackEvent"), b("startTrackPage"), b("stopTrackPage"), b("flush"), !a.disableExceptionTracking) { f = "onerror", b("_" + f); var g = e[f]; e[f] = function (a, b, d, e, h) { var i = g && g(a, b, d, e, h); return !0 !== i && c["_" + f](a, b, d, e, h), i } } return c
			}({
				instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHT_KEY,
			});

			window.appInsights = appInsights, appInsights.queue && 0 === appInsights.queue.length;

			this.appInstance = appInsights;
				}
			else {
				this.deleteCookie('ai_session');
				this.deleteCookie('ai_user');
    		}
	}
	
	trackEventBtnPage(nameAction) {
		let copy1 = this.cleanName(router.currentRoute.name);
		let copy2 = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('Page_' + copy1 + '_ActionBtn_' + copy2);
		}
	}

	trackEventBtnPageWithMetaInfo(nameAction, metaInfo) {
		let copy1 = this.cleanName(router.currentRoute.name);
		let copy2 = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('Page_' + copy1 + '_ActionBtn_' + copy2, metaInfo);
		}
	}

	trackEventBtnNavBar(nameAction) {
		let copy = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('NavBar_ActionBtn_' + copy);
		}
	}

	trackEventBtnFooter(nameAction) {
		let copy = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('Footer_ActionBtn_' + copy);
		}
	}

  trackEventBtnProfile(nameAction) {
		let copy = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('Profile_ActionBtn_' + copy);
		}
  }

	trackEventBtnModal(nameModal, nameAction) {
		let copy1 = this.cleanName(nameModal);
		let copy2 = this.cleanName(nameAction);
		if (this.allowCookies) {
		  this.appInstance.trackEvent('Modal_' + copy1 + '_ActionBtn_' + copy2);
		}
	}

	trackPageView() {
		let copy = this.cleanName(router.currentRoute.name);
		if (this.allowCookies) {
		  this.appInstance.trackPageView(copy);
		}
	}

	cleanName(name) {
		let copy = name;
		copy = copy.replace(/\s+/g, '');
		return copy;
	}

	deleteCookie(name) {
		document.cookie = name + '= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  }

	addOneTrustHelperExtension() {

		const self = this;

		window.otFordHelper = {
			groupConsent: function (groupID) {
				var consent = true;
				var cookies = document.cookie.split(';').reduce(function (map, obj) {
					var cookieString = obj.trim();
					var firstEqual = cookieString.indexOf('=');
					var key = cookieString.substring(0, firstEqual);
					var value = cookieString.substring(firstEqual + 1);
					map[key] = value;
					return map;
				}, []);
				var otConsentCookie = cookies['OptanonConsent'];
				if (otConsentCookie && otConsentCookie.length > 0) {
					var values = otConsentCookie.split('&').reduce(function (map, obj) {
						var equal = obj.indexOf('=');
						var key = obj.substring(0, equal);
						var value = decodeURIComponent(obj.substring(equal + 1));
						map[key] = value;
						return map;
					}, []);
					var otConsentGroups = values['groups'];
					if (otConsentGroups && otConsentGroups.length > 0) {
						var groups = otConsentGroups.split(',');
						var group = groups.filter(function (group) {
							var id = group.split(':')[0];
							return id === groupID.toString();
						})[0];
						if (typeof group !== 'undefined' && group.split(':')[1] === '0') {
							consent = false;
						}
					}
				}
				return consent;
			},
		}

		window.addEventListener('consent.onetrust', function (e) {
			if (window.otFordHelper && window.otFordHelper.groupConsent(6) === false) {
				self.deleteCookie('ai_session');
				self.deleteCookie('ai_user');
				self.allowCookies = false;
				if (window.appInsights) {
					window.appInsights.config.disableTelemetry = true;
					window.appInsights.config.instrumentationKey = null;
				}
			}
		});
	}
}