import baseAPI from './baseAPI';

export default class Authenticate extends baseAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/auth';
  }

  token(loginData) {
    return this.axiosObject.post(`${this.apiBaseName}/token`, loginData);
  }

  authenticate(headers) {
    return this.axiosObject.post(this.apiBaseName, null, headers);
  }

  create(dataObject) {
    return this.axiosObject.post(`${this.apiBaseName}/Create`, dataObject);
  }

  getUserByCdsId(cdsId, cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/getUserbyCdsId/${cdsId}${cachedParams}`);
  }

  getUserByStarsId(starsId, cached) {
    const cachedParams = cached ? '' : (`?unique=${Math.round(+new Date() / 1000)}`);
    return this.axiosObject.get(`${this.apiBaseName}/getUserbySTARS/${starsId}${cachedParams}`);
  }

  createUser(userObject) {
    return this.axiosObject.post(`${this.apiBaseName}/createUser/`, userObject);
  }

  oAuthAuthorize() {
    return this.axiosObject.get(`${this.apiBaseName}/oAuth/loginURL`);
  }

  oAuthToken(tokenObject) {
    return this.axiosObject.post(`${this.apiBaseName}/oAuth/token`, tokenObject);
  }
}
