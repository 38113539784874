import baseCRUDAPI from './baseCRUDAPI';

export default class Comment extends baseCRUDAPI {
  constructor(baseURLPath) {
    super(baseURLPath);
    this.apiBaseName = '/comment';
  }

  full(commentObject) {
    const path = `${this.apiBaseName}/full`;
    return this.axiosObject.post(path, commentObject);
  }
}
