<template>
  <div class="profileCompact" @click="clickedBadge">
    <div class="imgBadge">
      <EngagementLevelBadge :badge="currentEngagementLevel"
                            :colored="true">
      </EngagementLevelBadge>
    </div>
    <div class="welcomeWrapper">
      <div class="txtName">Welcome, {{this.profile.nameFirst}}</div>
      <div class="circleBorderProfileImg" :class="engagementLevelClass">
        <UserImageThumbnail :thumbnailAssetId="profile.thumbnailAssetId" :class="'imgProfile'" />
      </div>
    </div>
  </div>
</template>

<script>
import Helper from '../../mixins/helperMethods.vue';
import EngagementLevelBadge from '../engagementLevel/EngagementLevelBadge.vue';
import UserImageThumbnail from './UserImageThumbnail.vue';

export default {
  name: 'ProfileCompact',
  mixins: [Helper],
  props: ['profile'],
  components: {
    EngagementLevelBadge,
    UserImageThumbnail,
  },
  methods: {
    clickedBadge() {
      this.$emit('menuSwitch', 'ProfileCompact');
      this.$analytics.trackEventBtnProfile('ToExpandedView');
    },
  },
  computed: {
    engagementLevelClass() {
      return this.currentEngagementLevel
        ? this.currentEngagementLevel.name.replace(' ', '-').toLowerCase()
        : '';
    },
    currentEngagementLevel() {
      if (!this.engagementLevels) {
        return null;
      }

      const copy = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => this.profile.points >= el.pointsToUnlock);

      return copy
        ? copy.reverse()[0]
        : this.engagementLevels[this.engagementLevels.length - 1];
    },
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
  },
};
</script>

<style lang="scss" scoped>
.profileCompact {
  z-index: 60;
  height: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  @include respond(md) {
    width: 220px;
    padding: 5px 10px;
    background-color: $ProfileBackground;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  > * {
    margin-left: 2%;
    margin-right: 2%;
  }

  .imgBadge {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 85px;
    height: 85px;
    cursor: pointer;
    display: none;

    @include respond(md) {
      display: block;
    }

    .img {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &.lincoln-savvy {
      background-image: linear-gradient(rgb(46, 49, 146),rgb(15, 117, 188))
    }

    &.lincoln-influential {
      background-image: linear-gradient(rgb(55, 34, 121), rgb(102, 45, 145))
    }

    &.lincoln-force {
      background-image: linear-gradient(rgb(129, 130, 133), rgb(189, 190, 192))
    }

    &.lincoln-legend {
      background-image: linear-gradient(rgb(36, 32, 33), rgb(88, 88, 90))
    }

    &.lincoln-notable {
      background-image: linear-gradient(rgb(239, 64, 54), rgb(239, 109, 72))
    }
  }

  .welcomeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .txtName {
      font-weight: $bold;
      @include font-size(0.75rem);
      display: none;

      @include respond(md) {
        display: block;
      }
    }

    .circleBorderProfileImg {
      background-color: rbga(0, 0, 0, 0);
      border: 3px solid $ProfileCircleBorder;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 15px;

      @include respond(md) {
        width: 45px;
        height: 45px;
        margin-right: 0;
      }

      .imgProfile {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        display: inline-block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
      &.lincoln-savvy {
        border-color: rgb(46, 49, 146);
      }

      &.lincoln-influential {
        border-color: rgb(55, 34, 121);
      }

      &.lincoln-force {
        border-color: rgb(129, 130, 133);
      }

      &.lincoln-legend {
        border-color: rgb(36, 32, 33);
      }

      &.lincoln-notable {
        border-color: rgb(239, 64, 54);
      }
    }
  }
}
</style>
