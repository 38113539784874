import apiManager from '../../api';

const brandStoryLikeStore = {
  namespaced: true,
  actions: {
    async getLikeStatus({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.brandStoryLike.get(id, true) }, { root: true });
      return resp.data;
    },
    async setLikeStatus({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.brandStoryLike.post({ brandStoryId: id }) }, { root: true });
      return resp.data;
    },
  },
};

export default brandStoryLikeStore;
