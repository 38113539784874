<template>
  <div class="searchCompact" @click="clickedIcon">
    <font-awesome-icon :icon="['fal', 'search']" size="2x" />
  </div>
</template>

<script>
export default {
  name: 'SearchCompact',
  methods: {
    clickedIcon() {
      this.$emit('menuSwitch', 'SearchCompact');
    },
  },
};
</script>

<style lang="scss" scoped>
.searchCompact {
  position: absolute;
  right: 65px;
  color: rgb(182, 182, 182);
}
</style>
