import apiManager from '../../api';

const assetStore = {
  namespaced: true,
  state: {
    currentAsset: null,
    cachedImages: [],
  },
  mutations: {
    setCurrentAsset: (state, currentAsset) => { state.currentAsset = currentAsset; },
    cacheAsset: (state, asset) => { state.cachedImages = [...state.cachedImages, asset]; },
  },
  actions: {
    async cachedAsset({ state, commit, dispatch }, id) {
      let asset = state.cachedImages.find(cache => cache.id === id);
      if (!asset) {
        asset = await dispatch('downloadAsset', id);
        asset.id = id;
        asset.img = window.URL.createObjectURL(new Blob([asset.data]));
        commit('cacheAsset', asset);
      }
      return asset;
    },
    async getAsset({ commit, dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.asset.get(id, true) }, { root: true });
      return commit('setCurrentAsset', resp.data);
    },
    async getAssetDoNotStore({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.asset.get(id, true) }, { root: true });
      return resp.data;
    },
    async downloadAsset({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.asset.download(id, true) }, { root: true });
      return resp;
    },
    async getBase64({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.asset.base64(id, true) }, { root: true });
      return resp.data;
    },
    async getFilePath({ dispatch }, id) {
      const resp = await dispatch('request', { req: apiManager.asset.filepath(id, true) }, { root: true });
      return resp.data;
    },
    async setAsset({ dispatch }, formDataFile) {
      const resp = await dispatch('request', { req: apiManager.asset.file(formDataFile) }, { root: true });
      return resp.data;
    },
    async setMultiAsset({ dispatch }, formDataFile) {
      const resp = await dispatch('request', { req: apiManager.asset.multifiles(formDataFile) }, { root: true });
      return resp.data;
    },
    async getAssetList({ dispatch }, assetIds) {
      const resp = await dispatch('request', { req: apiManager.asset.filelist(assetIds, true) }, { root: true });
      return resp.data;
    },
    async removeAsset({ dispatch }, assetId) {
      const resp = await dispatch('request', { req: apiManager.asset.delete(assetId) }, { root: true });
      return resp.data;
    },
  },
  getters: {
    currentAsset: state => state.currentAsset,
  },
};

export default assetStore;
